import React from "react"
import MainLayout from "../components/MainLayout"
import ProductCard from "../components/ProductCard"
import K317Image from "../images/2019-kodiak-k3-17-disk-scrubber-front-view-side-twist.png"
import K320Image from "../images/2019-kodiak-k3-20-disk-scrubber-front-view-side-twist.png"
import K320OImage from "../images/2019-kodiak-k3-20-orbital-front-view-side-twist-back.png"
import K324CImage from "../images/2019-kodiak-k3-24-cylindrical-scrubber-front-view-side-twist.807457116.png"

import { ProductsPageStyled } from "../components/styles/ProductsPageStyled"
import PageHeading from "../components/PageHeadings"
import DemoSection from "../components/DemoSection"

const SSeriesPage = () => {
  return (
    <MainLayout>
      <ProductsPageStyled>
        <div>
          <PageHeading
            pageHeading="Floor Scrubbers: S-Series"
            // description="cray direct trade  readymade narwhal YOLO cornhole Kickstarter kitsch wayfarers artisan synth Vice seitan polaroid food truck Banksy fingerstache Thundercats art party cred chia next level Pinterest slow-carb before  Life disrupt"
          />

          <div id="product-card-container">
            <ProductCard
              image={K317Image}
              heading="K3-17 Disk"
              description="here is a brief description of the product and what it looks like when it wraps"
              link="/product"
            />

            <ProductCard
              image={K320Image}
              heading="K3-20 Disk"
              description="here is a brief description of the product and what it looks like when it wraps"
            />
            <ProductCard
              image={K320OImage}
              heading="K3-20 Orbital"
              description="here is a brief description of the product and what it looks like when it wraps"
            />
            <ProductCard
              image={K324CImage}
              heading="K3-24 Cylindrical"
              description="here is a brief description of the product and what it looks like when it wraps"
            />
          </div>
        </div>
      </ProductsPageStyled>
      <DemoSection />
    </MainLayout>
  )
}

export default SSeriesPage
